import colors from '../config/colors';

const extractColor = (color, lightMode) => {
    let colorName = color;
    if (lightMode) colorName += `Light${lightMode}`;
    return colors[colorName];
};

const reverseValue = (value = '') => value.split('').reverse().join('');

const formatPriceValue = (value, withEuro = true) => {
    const stringValue = String(value);
    let formattedPrice = stringValue;

    if (formattedPrice.length > 3) {
        const firstGroupLength = stringValue.length % 3;
        const lastGroups = [];
        let temp = '';

        for (let i = stringValue.length - 1; i > firstGroupLength - 1; i -= 1) {
            temp += stringValue[i];
            if (temp.length % 3 === 0) {
                lastGroups.push(reverseValue(temp));
                temp = '';
            }
        }

        formattedPrice = stringValue.substring(0, firstGroupLength) + ' ' + lastGroups.reverse().join(' ');
    }

    formattedPrice += withEuro ? ' €' : '';

    return formattedPrice;
};

function formatCountValue(countValue = 0) {
    if (countValue === 0) return '0';

    return String(countValue).padStart(2, '0');
}

export function isValidValue(value) {
    return !isNullish(value);
}

export function isNullish(value) {
    return [undefined, null].includes(value);
}

export function isDateValue(value) {
    if (typeof value === 'number') return false;

    return !Number.isNaN(Date.parse(value));
}

export function isFirefoxBrowser() {
    return navigator.userAgent.includes('Firefox');
}

export function toImageSlides(fileDownloadUrls = []) {
    return fileDownloadUrls.map((fileDownloadUrl) => ({ fileDownloadUrl }));
}

export function isNonEmptyString(value) {
    return isValidValue(value) && typeof value === 'string' && value.length > 0;
}

export function sortDocumentsByPropertyAscendant({ documents = [], property } = {}) {
    return documents.sort((prevDocument, nextDocument) => {
        if (prevDocument[property] < nextDocument[property]) return -1;
        else if (prevDocument[property] > nextDocument[property]) return 1;

        return 0;
    });
}

export function toNonNullString(value) {
    return value || '';
}

export function isString(value) {
    return typeof value === 'string';
}

export default {
    extractColor,
    formatPriceValue,
    formatCountValue,
    isDateValue
};
